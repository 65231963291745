<template>
  <div class="message-detail">
    <Header />
    <div class="content statstics">
      <div class="item">
        <div class="label">经销商编号</div>
        <div class="value">{{messageInfo.kjDealer}}</div>
      </div>
      <div class="item">
        <div class="label">经销商名称</div>
        <div class="value">{{messageInfo.dealerName}}</div>
      </div>
      <div class="item">
        <div class="label">申请人</div>
        <div class="value">{{messageInfo.createName}}</div>
      </div>
    </div>

    <div class="content msg">
      <div class="title">短信内容</div>
      <div class="msg-content">{{messageInfo.smsContent}}</div>
    </div>
  </div>
</template>

<script>
import { fetchMessageDetail } from '@/api/message';
import Header from '@/components/header';
export default {
  components: {
    Header,
  },
  activated() {
    this.getMessageDetail();
  },
  deactivated() {
    this.messageInfo = {};
  },
  data() {
    return {
      messageInfo: {},
    }
  },
  methods: {
    async getMessageDetail() {
      const res = await fetchMessageDetail({ sendBatch: this.$route.query.sendBatch });
      this.messageInfo = res.data;
    }
  },
}
</script>

<style lang="scss" scoped>
.message-detail {
  text-align: left;
  min-height: 100vh;
  background-color: #FAFBFC;

  .content {
    margin-top: 12px;
    width: 100%;
    background-color: #fff;
    padding: 10px 24px;
    box-sizing: border-box;
    &.statstics {
      padding: 15px 24px 18px;
      box-sizing: border-box;
      .item {
        margin-top: 6px;
        display: flex;
        align-items: center;
        .label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #818181;
        }
        .value {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #2C2C2C;
          margin-left: 6px;
        }
      }
    }
    &.msg {
      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #91939A;
      }
      .msg-content {
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
        color: #2C2C2C;
        margin-top: 12px;
      }
    }
  }
}
</style>